export const GROUPS_WIDGET_ID = 'a7dcdfcb-8abd-4008-af19-fed5fcd12b40';
export const GROUPS_LIST_WIDGET_ID = '0a9f687f-7e00-4576-a8e1-9415844b8f44';
export const GROUPS_LIST_DISCOVERY_WIDGET_ID =
  '83b2af08-c021-40c8-a3a5-b329a959ec2b';
export const GROUP_WIDGET_ID = '513a5d84-3ebb-4ca6-a5aa-83effd2123b9';
export const CONTEXT_CALLBACKS_APP_ID = 'c0b88324-5294-4255-a601-0e426091c233';
export const GROUPS_APP_ID = '148c2287-c669-d849-d153-463c7486a694';
export const GROUPS_MEMBER_AREA_WIDGET_ID =
  'c1d78264-dd73-4564-969f-d47d619f7051';
export const GROUP_LIST = 'group_list';

export const MEMBERS_APP_DEF_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';

export const PAID_PLANS_APP_DEF_ID = '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3';
export const INVITE_PAID_PLANS = 'invite_paid_plans';

// Should be in sync with dev center page id
export const GROUPS_MEMBERS_AREA_PAGE_ID = 'groups_area';
export const GROUPS_PLATFORM_CONTROLLER_TYPE = 'groupsPlatform';
export const GROUP_DISCUSSION_ID = 'discussion';

// page Id from https://dev.wix.com/
export const GROUPS_PAGE_ID = 'groups';
export const GROUP_PAGE_ID = 'group';
export const ABOUT_GROUP_PAGE_ID = 'about_group';
export const GROUP_PAGES = [GROUP_PAGE_ID, GROUPS_PAGE_ID];

export const PAGE_TITLES = {
  [GROUP_PAGE_ID]: 'groups-web.initial.page-name.group-page',
  [GROUPS_MEMBERS_AREA_PAGE_ID]:
    'groups-web.initial.page-name.groups-members-area-page',
  [GROUPS_PAGE_ID]: 'groups-web.initial.page-name.groups-page',
};

export const GROUPS_DASHBOARD_URL = 'social-groups-dashboard';

export const SELECT_ALL_MEMBER_ID = 'select-all';

export const WIX_STATIC = 'https://static.wixstatic.com';
export const WIX_STATIC_VIDEO_BASE_URL = 'https://video.wixstatic.com/video';
